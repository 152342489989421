var csrf_token = '{{ csrf_token() }}';
$(document).ready(function (){
    $('.btn-add-cart').click(function (event) {
        event.preventDefault();
        let id = $('.product-name').data('id');
        let qtybox;
        if(!id) {
          newid = $(this).data('id');
          qtybox = $(this).data('box');
        } else {
          newid = id;
        }
        addToCart(newid, qtybox);
    });
    $('.btn-add-like').click(function (event) {
        let id = $(this).data('id');
        let active = $(this).data('active');
        if(active == false) {
            addToLike(id);
            $(this).data("active", true);
        } else {
            delToLike(id);
            $(this).data("active", false);
        }
    });
    $('#cart_table').on('click', '.del_cart_product', function() {
        let countId = $(this).data('id');
        delProduct(countId);
    });
    $('#cart_table').on('change', '.count_modal', function() {
        let countId = $(this).data('id');
        let countQty = parseInt($(this).val());
        upProduct(countId, countQty);
   });
});
function upProduct(id, qty){
    $.ajax({
      url: "/up-to-cart", // куда отправляем
      type: "post", // метод передачи
      dataType: "json", // тип передачи данных
      data: {
        id: id,
        qty: qty
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      // после получения ответа сервера
      success: (data) => {
        $.each(data, function(index, value){
          let newPrice = value.price * qty;
          let finalNewPrice = newPrice.toFixed(2);
          let totalNow = 0;
          let qtyNow = 0;
          if(id == value.id){
            $('#count_price_'+id).text(finalNewPrice);
          }
          $(".count_price").each(function() {
              totalNow += parseFloat($(this).text());
          });
          $(".count_modal").each(function() {
              qtyNow += parseInt($(this).val());
          });
          $('.count_cart').text(qtyNow);
          $('.count_cart_mob').text(qtyNow);
          $('#full_total').text(totalNow.toFixed(2));
        });
      },
      error: (data) => {
      }
    });
}
function addToLike(id) {
    $.ajax({
        url: "/add-to-like", // куда отправляем
        type: "post", // метод передачи
        dataType: "json", // тип передачи данных
        data: {
            id: id,
            qty: 1
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        // после получения ответа сервера
        success: (result) => {
            let qty = 1;
            let total_qty = $('.count_like').text() == '' ? 0 : parseInt($('.count_like').text());
            total_qty += qty;
            $('.count_like').text(total_qty);
            $("#like_products_items").empty();
            $.each(result, function(index, value){
            let addedProduct = '<div class="product" id="lproduct_'+ value.id +'">'+
            '<div class="image">'+
            '<div>'+
             '<a href="/product/'+ value.id  +'">'+
                '<img src="'+ value.attributes.images +'" alt="'+ value.name +'">'+
                '</a>'+
                '</div>'+
                '</div>'+
                '<a href="/product/'+ value.id  +'" class="text">'+ value.name +'</a>'+
                '<br>'+
                '<div class="cost">от '+ value.price.toFixed(1) +' ₽</div>'+
                '</div>';
                $("#like_products_items").append(addedProduct);
            });
        },
        error: (result) => {
            // console.log(result);
        }
      });
}
function addToCart(newid, qtybox) {
    let id = $('.product-name').data('id');
    let qty = parseInt($('#qty_input').val());
    /*Новый код*/
    if(isNaN(qty)){
      qty = qtybox;
    }
    if(!id) {
      id = newid;
    }
    /*конец нового кода*/
    let total_qty = $('.count_cart').text() == '' ? 0 : parseInt($('.count_cart').text());
    total_qty += qty;
    $('.count_cart').text(total_qty);
    $('.count_cart_mob').text(total_qty);
    $.ajax({
        url: "/add-to-cart", // куда отправляем
        type: "post", // метод передачи
        dataType: "json", // тип передачи данных
        data: {
            id: id,
            qty: qty,
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        // после получения ответа сервера
        success: (result) => {
            $(".cart_body").empty();
            let full_total = 0;
            $.each(result, function(index, value){
            full_total += parseInt(value.price * value.quantity);
            one_total = parseFloat(value.price * value.quantity);
            let prop = value.attributes.props.name[0] ? value.attributes.props.name[0] + ' : ' : '';
            let prop2 = value.attributes.props.value[0] ? value.attributes.props.value[0] : '';
            let prop3 = value.attributes.props.value[1] ? value.attributes.props.value[1] : '';
            let addedProduct = '<tr data-item>'+
                               '<td>'+
                               '<div class="d-flex align-items-center">'+
                                '<a href="/product/'+ value.id +'" class="flex-shrink-0 img-wrap">'+
                        '<img class="img" src="'+ value.attributes.images +'">'+
                    '</a>'+
                   '<div class="flex-grow-1 desc">'+
                        '<a href="/product/'+ value.id +'" class="name">'+ value.name +'</a>'+
                        '<div class="param">'+ prop + prop2 +'</div>'+
                    '</div>'+
                '</div>'+
            '</td>'+
            '<td class="text text-center">'+ prop3 +'</td>'+
            '<td class="text text-center">'+
                '<div class="plus_minus" data-plus-minus>'+
                   '<div class="minus" data-min-minus="'+ value.attributes.minqty +'" data-minus>-</div>'+
                    '<input type="number" class="number count_modal" data-id="'+ value.id +'" data-price="'+ value.price +'" value="'+ value.quantity +'">'+
                    '<div class="plus" data-min-plus="'+ value.attributes.minqty +'" data-plus>+</div>'+
               '</div>'+
            '</td>'+
           '<td>'+
                '<div class="d-flex justify-content-between price-wrap">'+
                    '<div class="price"><span class="count_price" id="count_price_'+ value.id +'">'+ one_total +'</span>₽</div>'+
                    '<span class="del_cart_product" data-id="'+ value.id +'">'+
                    '<svg data-delete class="delete" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                        '<rect x="0.0898438" y="14.0586" width="19.8814" height="1.33176" transform="rotate(-45 0.0898438 14.0586)" fill="#999999" />'+
                        '<rect x="14.2148" y="15" width="19.8814" height="1.33176" transform="rotate(-135 14.2148 15)" fill="#999999" />'+
                    '</svg>'+
                    '</span>'+
                '</div>'+
            '</td>'+
        '</tr>"';
        $(".cart_body").append(addedProduct);
        });
        $('#full_total').text(full_total.toFixed(2));
        },
        error: (result) => {
            // console.log(result);
        }
      });
}
function delProduct(id){
    $.ajax({
      url: "/del-to-cart", // куда отправляем
      type: "post", // метод передачи
      dataType: "json", // тип передачи данных
      data: {
        id: id
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      // после получения ответа сервера
      success: (data) => {
        $( "#cart_product_"+id).remove();
        let totalNow = 0;
        let qtyNow = 0;
        $(".count_price").each(function() {
            totalNow += parseInt($(this).text());
        });
        $(".count_modal").each(function() {
            qtyNow += parseInt($(this).val());
        });
        $('.count_cart').text(qtyNow);
        $('.count_cart_mob').text(qtyNow);
        $('#full_total').text(totalNow);
      },
      error: (data) => {
      }
    });
  }
  function delToLike(id){
    $.ajax({
      url: "/del-to-like", // куда отправляем
      type: "post", // метод передачи
      dataType: "json", // тип передачи данных
      data: {
        id: id
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      // после получения ответа сервера
      success: (result) => {
        let qty = 1;
        let total_qty = $('.count_like').text() == '' ? 0 : parseInt($('.count_like').text());
        total_qty -= qty;
        $('.count_like').text(total_qty);
        $("#lproduct_"+id).remove();
    },
    error: (result) => {
        // console.log(result);
    }
    });
  }