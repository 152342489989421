window.$ = window.jQuery = require('jquery');
require('jquery-modal');
require('./libwebp-0.6.0.min');
require('./demux');
require('./viewer');
require('./theme');
require('./webp');
require('./scripts');
require('./cart');


window.changeMessage = function (mesVal) {
    $("#callback_title").html(mesVal);
    $("#message").val(mesVal);
}
$(document).ready(function () {
      $('.cartbtn').click(function() {
        let number = $(this).data('number-toggle');
        $(this).toggle();

        $('#added_'+number).toggle();
      });
      $('.form').on('submit', function (e) {
          e.preventDefault();
          const form = $(this);
          $.ajax({
              type: 'POST',
              url: '/send',
              data: form.serialize(),
                headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
              },
              success: function (data) {
                  form.find('.messages').empty();
                  if (data.errors) {
                      const errors = data.errors;
                      for (let key in errors) {
                          form.find(`input[name="${key}"]`).addClass('is-invalid');
                          form.find('.messages').append(`<span style='color: red;margin-bottom: 15px;display: block;'>${errors[key]}</span><br/>`);
                      }
                  } else {
                      form.find('.messages').append(`<span style='color: green;margin-bottom: 15px;display: block;'>${data.success}</span><br/>`);
                      form[0].reset();
                  }
              },
              error: function (data) {
                  console.log(data);
              }
          });
          $.ajax({
            type: 'POST',
            url: '/action.php',
            data: $('.form').serialize(),
            headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
            },
            error: function (data) {
            }
        });
      });
      $('.form_cart').on('submit', function (e) {
        e.preventDefault();
        const form = $(this);
        $.ajax({
            type: 'POST',
            url: '/sendcart',
            data: $('.form_cart').serialize(),
              headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
              form.find('.messages').empty();
                if (data.errors) {
                    const errors = data.errors;
                    for (let key in errors) {
                        form.find(`input[name="${key}"]`).addClass('is-invalid');
                        form.find('.messages').append(`<span style='color: red;margin-bottom: 15px;display: block;'>${errors[key]}</span><br/>`);
                    }
                } else {
                  console.log(data);
                }
            },
            error: function (data) {
                console.log(data);
            }
        });
        $.ajax({
          type: 'POST',
          url: '/action.php',
          data: $('.form_cart').serialize(),
          headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          },
          success: function (data) {
            alert('Заказ успешно оформлен! Ожидайте звонка!');
            location.reload();
          },
          error: function (data) {
          }
      });
    });
    $('#filter').change(function(){
        let cat_id = $('#filter').data('cat');
        let data = $('#filter').serializeArray();
        var checkcount = $("input:checked").length;
        data.push({name: 'cat_id', value: cat_id});
        if(checkcount > 0){
        $.ajax({
            url: '/fill-products', // передаем на текущую страницу (чтобы обработал контроллер текущей страницы - функция index запуститься, там у нас фильтр применяется как я показывал тебе)
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: (result) => {
                $("#products_items").empty();
                $.each(result, function(index, newvalue){
                    $.each(newvalue, function(index, value){
                let prop = value.props.name[0] ? value.props.name[0] + ' : ' : '';
                let prop2 = value.props.value[0] ? value.props.value[0] : '';
                let prop3 = value.props.name[1] ? value.props.name[1] : '';
                let prop4 = value.props.value[1] ? value.props.value[1] : '';
                let addedProduct = '<div class="product">'+
                                        '<div class="image">'+
                                            '<div>'+
                                            '<div class="head">'+
                                                '<div class="discount">'+
                                                    '<span class="btn-add-like" data-id="'+value.id+'" data-active="false">'+
                                                    '<svg class="icon" data-product-like width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                                                    '<path d="M24 0H0V24H24V0Z" fill="white" />'+
                                                    '<path d="M7.5 4C4.46244 4 2 6.46245 2 9.5C2 15 8.5 20 12 21.1631C15.5 20 22 15 22 9.5C22 6.46245 19.5375 4 16.5 4C14.6399 4 12.9954 4.92345 12 6.3369C11.0046 4.92345 9.36015 4 7.5 4Z" stroke="#111111" stroke-linecap="round" stroke-linejoin="round" />'+
                                                    '</svg>'+
                                                    '<svg class="icon" data-product-unlike style="display: none;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                                                    '<path d="M24 0H0V24H24V0Z" fill="white" />'+
                                                    '<path d="M7.5 4C4.46244 4 2 6.46245 2 9.5C2 15 8.5 20 12 21.1631C15.5 20 22 15 22 9.5C22 6.46245 19.5375 4 16.5 4C14.6398 4 12.9953 4.92345 12 6.3369C11.0047 4.92345 9.36015 4 7.5 4Z" fill="#712CF9" stroke="#712CF9" stroke-linecap="round" stroke-linejoin="round" />'+
                                                    '</svg>'+
                                                    '</span>'+
                                                '</div>'+
                                                '</div>'+
                                            '<a href="/product/'+value.id+'">'+
                                            '<img src="'+value.images+'" alt="'+value.name+'">'+
                                            '</a>'+
                                         '</div>'+
                                        '</div>'+
                                        '<a href="/product/'+value.id+'" class="text">'+value.name+'</a>'+
                                        '<div class="param">'+prop + prop2 +'</div>'+
                                        '<div class="param">'+prop3 + prop4 +'</div>'+
                                        '<br>'+
                                        '<div class="cost">от '+value.amount.toFixed(2)+' ₽</div>'+
                                        '<div class="cost old">от '+value.amount_old.toFixed(2)+' ₽</div>'+
                                        '</div>';
                    $("#products_items").append(addedProduct);
                    });
                });
            }
        });
        } else {
            location.reload();
        }
    });
    // Phone Input Mask
function getChar(event) {
    if (event.which === null) { // IE
      if (event.keyCode < 32) return null; // спец. символ
      return String.fromCharCode(event.keyCode)
    }
  
    if (event.which !== 0 && event.charCode !== 0) { // все кроме IE
      if (event.which < 32) return null; // спец. символ
      return String.fromCharCode(event.which); // остальные
    }
  
    return null; // спец. символ
  }
  
  const phoneControl = document.querySelectorAll('input[name="phone"]');
  phoneControl.forEach(function (el) {
    el.onkeypress = function (e) {
      e = e || event;
  
      if (e.ctrlKey || e.altKey || e.metaKey) return;
  
      const chr = getChar(e);
  
      if (chr === null) return;
  
      if (this.value.length > 16) {
        return false;
      }
  
      if (chr < '0' || chr > '9') {
        return false;
      } else {
        if (!this.value) {
          if (chr === '7' || chr === '8') {
            this.value += '+7 (';
          } else {
            this.value += '+7 (' + chr;
          }
          return false;
        }
  
        if (this.value.length === 7) {
          this.value += ') ';
        }
  
        if (this.value.length === 12) {
          this.value += '-';
        }
      }
    };
  
    el.onpaste = function (e) {
      return false;
    }
  });
});

$(function() {

	$(".prev").on('click', function(event) {
		event.preventDefault();
		prevSlide();
	});

	$(".next").on('click', function(event) {
		event.preventDefault();
		nextSlide();
	});

	if ($(".item").length <= 1) {
		$(".next").addClass('hide-nav');
	}

	$(".prev").addClass('hide-nav');

	function nextSlide() {
		var atual = $(".cd-slider").find('.current'),
			next = atual.next();

		next.addClass('current').removeClass('prev_slide').siblings().removeClass('current');
		next.prevAll().addClass('prev_slide');

		if (next.index() > 0) {
			$(".prev").removeClass('hide-nav');
		}
		if (next.index() == $(".item").last().index()) {
			$(".next").addClass('hide-nav');
		}
	}

	function prevSlide() {
		var atual = $(".cd-slider").find('.current'),
			prev = atual.prev();

		prev.addClass('current').removeClass('prev_slide').siblings().removeClass('current');

		if (prev.index() !== $(".item").last().index()) {
			$(".next").removeClass('hide-nav');
		} 
		if (prev.index() == 0) {
			$(".prev").addClass('hide-nav');
		}
	}

});